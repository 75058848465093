var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered modal-lg" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  ref: "popupCloseButton",
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "popup-body" }, [
                _c("div", { staticClass: "row" }, [
                  _vm.userPickl
                    ? _c("div", { staticClass: "col-md-12" }, [
                        _vm.userPickl.user_pickl_task_sync_log.length > 0
                          ? _c("div", { staticClass: "form-group" }, [
                              _c(
                                "h2",
                                { staticStyle: { "text-align": "center" } },
                                [_vm._v("Task Images Uploading Log")]
                              ),
                              _c(
                                "table",
                                { staticClass: "table table-striped" },
                                [
                                  _vm._m(0),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.userPickl.user_pickl_task_sync_log,
                                      function(log) {
                                        return _c("tr", { key: log.id }, [
                                          _c("td", [
                                            _vm._v(_vm._s(log.status))
                                          ]),
                                          _c("td", [_vm._v(_vm._s(log.error))]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formattedPhpTime")(
                                                  log.created_at
                                                )
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm._f("formattedPhpDate")(
                                                    log.created_at
                                                  )
                                                )
                                            )
                                          ])
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ])
                          : _c("div", [
                              _c("p", [
                                _vm._v("No Task Image Uploading logs found")
                              ])
                            ])
                      ])
                    : _c("div", { staticClass: "col-md-12" }, [
                        _c("p", [_vm._v("No Task Image Uploading logs found")])
                      ])
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _c("th", [_vm._v("Error")]),
        _c("th", [_vm._v("Date/Time")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }