<template>
  <!-- <div :id="popupId" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" > -->
  <div
    class="modal-dialog modal-dialog-centered modal-lg"
    v-if="pickl && Object.keys(pickl).length > 0"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2 v-if="!brand && pickl.status === 'COMPLETED'">Approve PICKL</h2>
        <h2 v-else>PICKL Details</h2>
        <button type="button" class="close" @click="$router.go(-1)"></button>
        <button type="button" class="btn btn-positive pull-right" @click="generateShareLink" v-if="$store.state.userData.role!='staff'">Share Task </button>
        <input type="text" v-model="sharedLink" class="pull-right" v-if="sharedLinkGenerated"/>
      </div>
      <div class="modal-body">
        <ul class="nav nav-tabs">
          <li class="active">
            <a data-toggle="tab" href="#pickl-details-section">Pickl Details</a>
          </li>
          <li>
            <a data-toggle="tab" href="#survey-details-section"
              >Survey Details</a
            >
          </li>
        </ul>
        <div class="tab-content">
          <div id="pickl-details-section" class="tab-pane fade in active">
            <br />
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <div class="green-border-box white-bg text-center first-block">
                  <div class="top-part">
                    <div class="posRel">
                      <div class="grey-logo-circle company-logo">
                        <img
                          class="img-in-circle"
                          :src="
                            pickl.store_location.store.image + '?w=150&q=50'
                          "
                          @error="
                            pickl.store_location.store.image =
                              '/img/default_store_image.png'
                          "
                        />
                      </div>
                      <div class="grey-logo-circle product-logo">
                        <img
                          class="img-in-circle"
                          :src="pickl.brand.logo + '?w=150&q=50'"
                          @error="pickl.brand.logo = '/img/pickl-logo.png'"
                        />
                      </div>
                      <h4>{{ pickl.store_location.store.name }}</h4>
                      <h5>{{ pickl.brand.name }}</h5>
                    </div>
                  </div>
                  <div class="middle-part">
                    <div class="address" :title="pickl.store_location.address">
                      {{ pickl.store_location.address }}
                    </div>
                    <div class="address" v-if="pickl.user_pickl">
                      <img src="/img/Alerts/pickl_icon.png" class="mr3" />
                      <strong>Picklr :</strong> {{ pickl.user_pickl.user.name }}
                      <br class="showOnMobile" />
                      <span v-if="!brand">
                        <img
                          src="/img/Alerts/callGreen_icon.png"
                          class="ml5 mr3"
                        />
                        {{ pickl.user_pickl.user.mobile_number | phone }}
                      </span>
                    </div>
                    <div class="address" v-else>
                      <img src="/img/Alerts/pickl_icon.png" class="mr3" />
                      <strong>Picklr :</strong> Not accepted
                    </div>
                  </div>
                  <div class="bottom-part">
                    <div class="col-xs-12 col-sm-6 pickl-date">
                      <img src="/img/Alerts/section_icon.png" />
                      {{ pickl.product.section }}
                    </div>
                    <div class="col-xs-12 col-sm-6 pickl-time">
                      <img src="/img/Picklr/time_icon.png" /> {{ pickl.date }}
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="green-border-box in-padd product-details" v-if="pickl.has_multiple_products == 1">
                  <!-- <h3 class="plain-center-header">Products</h3> -->

                  <div v-for="(product, index) in pickl.products" :key="product.id">
                    <h4>Product Page {{ index+1 }} : {{ product.product.name }}</h4>
                    <div class="row attr-imgs">
                      <viewer
                        :images="product.product.images"
                        :options="viewerOptions"
                      >
                        <div
                          class="col-xs-4 col-md-3 photo"
                          v-for="(photo, index) in product.product.images"
                          :key="product.product.id + '-' + index"
                        >
                          <div class="img-contain">
                            <img
                              class="vertical-center-transform"
                              :src="photo.image + lowSrcQuery"
                              :alt="
                                photo.description === null
                                  ? pickl.product.name
                                  : photo.description
                              "
                            />
                          </div>
                          <div
                            class="attr-time text-center"
                            :title="photo.description || ''"
                          >
                            {{ photo.description || '&nbsp;' }}
                          </div>
                        </div>
                      </viewer>
                    </div>
                  </div>
                </div>
                <div class="green-border-box in-padd product-details" v-else>
                  <h3 class="plain-center-header">{{ pickl.product.name }}</h3>
                  <div class="row attr-imgs">
                    <viewer
                      :images="pickl.product.images"
                      :options="viewerOptions"
                    >
                      <div
                        class="col-xs-4 col-md-3 photo"
                        v-for="(photo, index) in pickl.product.images"
                        :key="pickl.product.id + '-' + index"
                      >
                        <div class="img-contain">
                          <img
                            class="vertical-center-transform"
                            :src="photo.image + lowSrcQuery"
                            :alt="
                              photo.description === null
                                ? pickl.product.name
                                : photo.description
                            "
                          />
                          <!-- <div class="img-overlay">
                                      <span class="img-count">{{index + 1}}</span>
                                      <span class="vertical-center-transform">{{photo.task?photo.task.name:'Extra Image'}}</span>
                                    </div> -->
                        </div>
                        <div
                          class="attr-time text-center"
                          :title="photo.description || ''"
                        >
                          {{ photo.description || '&nbsp;' }}
                        </div>
                      </div>
                    </viewer>

                    <div
                      class="col-xs-6 col-md-4 photo"
                      v-for="n in dummyProductImages"
                      :key="'dummy-product-' + n"
                    >
                      <div class="img-contain missing-image"></div>
                      <div class="attr-time text-center">NA</div>
                    </div>
                  </div>
                </div>
                <div class="green-border-box in-padd normal-text posRel issues">
                  <div
                    v-if="
                      !pickl.user_pickl ||
                        (!isSadmin && pickl.status !== 'APPROVED') ||
                        (isSadmin &&
                          (pickl.status == 'PENDING' ||
                            pickl.status == 'ACCEPTED' ||
                            pickl.status == 'EXPIRED'))
                    "
                  >
                    <div class="overlay">
                      <div class="vertical-center-transform">
                        Picklr feedback not available
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xs-12 mb20">
                        <label>Issues</label>
                        None
                      </div>
                      <div class="col-xs-12 mb20">
                        <label>Notes</label>
                        None
                      </div>
                      <div class="col-xs-6">
                        <label>Buy?</label>
                        Maybe
                      </div>
                      <div class="col-xs-6">
                        <label>Shop Today?</label>
                        Maybe
                      </div>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-xs-12 mb20">
                      <label>Issues</label>
                      <span
                        v-if="
                          pickl.user_pickl &&
                            pickl.user_pickl.issues &&
                            pickl.user_pickl.issues.length > 0
                        "
                      >
                        <li
                          v-for="issue in pickl.user_pickl.issues"
                          :key="issue.id"
                        >
                          {{ issue.issue }}
                        </li>
                      </span>
                      <span v-else>None</span>
                    </div>
                    <div class="col-xs-12 mb20">
                      <label>Notes</label>
                      <div v-if="isSadmin">
                        <div>
                          {{ pickl.user_pickl.note || 'None' }}
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="pickl.user_pickl.show_notes_to_brand == 1">
                          {{ pickl.user_pickl.note || 'None' }}
                        </div>
                        <div v-else>
                          None
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xs-6"
                      v-if="pickl.user_pickl.pickl_answers"
                      v-for="answer in pickl.user_pickl.pickl_answers"
                      :key="answer.id"
                    >
                      <label>{{ answer.pickl_question.question }}</label>
                      {{ answer.answer }}
                    </div>
                  </div>
                </div>
                <div class="green-border-box rating in-padd normal-text posRel">
                  <div
                    v-if="
                      pickl.status === 'PENDING' ||
                        pickl.status === 'ACCEPTED' ||
                        (brand && pickl.status !== 'APPROVED') ||
                        pickl.status === 'EXPIRED'
                    "
                  >
                    <div class="overlay">
                      <div class="text-center vertical-center-transform">
                        Rating not available
                      </div>
                    </div>
                    <div
                      v-if="
                        pickl.user_pickl &&
                          pickl.user_pickl &&
                          pickl.user_pickl.need_review == 'Yes'
                      "
                      class="text-center mt10"
                    >
                      <span class="completion-time">ATTENTION NEEDED</span>
                    </div>

                    <div class="rated">
                      <label>Notes</label>
                      None

                      <br /><br /><strong
                        >{{ brand ? 'Admin' : 'You' }} rated to Picklr </strong
                      ><img
                        v-for="n in 5"
                        :key="n"
                        src="/img/Alerts/picklerating_icon.png"
                      />
                    </div>
                  </div>
                  <div
                    v-if="!brand && pickl.status === 'COMPLETED'"
                    class="text-center"
                  >
                    <h5>Rate the Picklr</h5>
                    <div class="rate-picklr" @mouseout="hoverRating = 0">
                      <span
                        v-for="i in 5"
                        :key="i"
                        @mouseover="hoverRating = i"
                        @click="rating = i"
                        ><img :src="getImagePath(i)"
                      /></span>
                    </div>
                    <textarea
                      maxlength="120"
                      placeholder="Notes"
                      v-model="adminNote"
                    ></textarea>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        v-model="ShowNotesToBrand"
                        checked
                      />Show Notes To Brand
                    </div>
                    <div class="col-md-6">
                      <input type="checkbox" v-model="needReview" />Attention
                      Needed
                    </div>
                  </div>
                  <div
                    v-if="
                      pickl.status === 'APPROVED' ||
                        (!brand && pickl.status === 'REJECTED')
                    "
                    class="rated"
                  >
                    <div
                      v-if="
                        pickl.user_pickl &&
                          pickl.user_pickl &&
                          pickl.user_pickl.need_review == 'Yes'
                      "
                      class="text-center mt10"
                    >
                      <span class="completion-time">ATTENTION NEEDED</span>
                    </div>

                    <label>Notes</label>
                    {{ pickl.user_pickl.admin_note || 'None' }}

                    <br /><br /><strong
                      v-if="pickl.user_pickl.admin_rating !== null"
                      >{{ brand ? 'Admin' : 'You' }} rated to Picklr </strong
                    ><img
                      v-for="n in pickl.user_pickl.admin_rating"
                      :key="n"
                      src="/img/Alerts/picklerating_icon.png"
                    />
                  </div>
                  <div
                    v-if="
                      !brand &&
                        pickl.status === 'REJECTED' &&
                        pickl.can_reverse_reject_pickl == true
                    "
                    class="col-xs-12 nopadd"
                  >
                    <div class="text-right">
                      <button
                        class="btn-lg-green"
                        @click="reverseRejectPickl"
                        style="width:250px;"
                      >
                        REVERSE REJECTION
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <div class="green-border-box in-padd first-block posRel">
                  <div>

                    <h3 class="plain-center-header">PICKL Journey</h3>
                    <div class="text-center">
                      <span
                        class="border-circle"
                        :style="{
                          color: $common.cardColors['PENDING'],
                          borderColor: $common.cardColors['PENDING']
                        }"
                      >
                        <span class="vertical-center-transform">
                          {{ pickl.start_date | formattedDate }}<br />
                          {{ pickl.start_time }}
                        </span>
                        <span class="circle-label">Launched</span>
                      </span>
                      <span
                        v-if="
                          pickl.user_pickl && pickl.user_pickl.user_pickl_log
                        "
                      >
                        <span
                          v-for="(log, index) in pickl.user_pickl
                            .user_pickl_log"
                          v-if="
                            isSadmin ||
                              (pickl.status !== 'COMPLETED' &&
                                pickl.status !== 'ACCEPTED') ||
                              index === 0
                          "
                          :key="log.id"
                          :style="{
                            color: $common.cardColors[log.type.toUpperCase()],
                            borderColor:
                              $common.cardColors[log.type.toUpperCase()]
                          }"
                        >
                          <hr class="journey-string" />
                          <span class="border-circle">
                            <span class="vertical-center-transform">
                              {{ log.updated_at | formattedPhpDate('short')
                              }}<br />
                              {{ log.updated_at | formattedPhpTime }}
                            </span>
                            <span class="circle-label">{{
                              getLogName(log.type)
                            }}</span>
                          </span>
                        </span>
                      </span>

                      <div
                        class="text-center mt10"
                        v-if="pickl.processing_time"
                      >
                        <span class="completion-time">{{
                          pickl.processing_time
                        }}</span>
                      </div>
                    </div>
                    <button
                    v-if="$store.state.userData.role=='super-admin' && (pickl.status == 'COMPLETED' || pickl.status == 'APPROVED' || pickl.status == 'REJECTED') && !brand"
                    type="button"
                    class="btn btn-positive pull-right"
                    data-toggle="modal"
                    data-target="#task-sync-log-popup"
                    style="margin-top: 20px;"
                  >
                    View Image Uploading Error Logs
                  </button>
                  </div>
                </div>
                <div class="green-border-box posRel">
                  <div class="attr-imgs">
                    <viewer :images="taskImages" :options="viewerOptions">
                      <div
                        v-for="(photo, index) in sorted_user_pickl_photos"
                        :key="photo.name"
                      >
                        <div class="col-xs-6 col-md-4 photo" v-if="photo.task">
                          <div class="img-contain">
                            <img
                              class="vertical-center-transform"
                              :src="photo.image + lowSrcQuery"
                              :alt="
                                getTaskImageName(photo)
                              "
                            />
                            <div class="img-overlay">
                              <span class="img-count">{{ index + 1 }}</span>
                              <span class="vertical-center-transform">{{ getTaskImageName(photo) }}</span>
                            </div>
                          </div>
                          <span class="attr-time"
                            ><img
                              src="/img/Alerts/timeGreen_icon.png"
                              class="mr3"
                            />
                            {{ photo.created_at | formattedPhpTime }}</span
                          >
                          <!-- <span class="attr-time pull-right"><router-link :to="'/sadmin/pickls/' + pickl.id + '/tasks/' + photo.task.id + '/competitions'"><img src="/img/Pickl details/section_icon.png" class="mr3" /></router-link></span> -->
                        </div>
                      </div>
                      <div
                        v-for="(photo, index) in sorted_user_pickl_photos"
                        :key="photo.id"
                      >
                        <div class="col-xs-6 col-md-4 photo" v-if="!photo.task">
                          <div class="img-contain">
                            <img
                              class="vertical-center-transform"
                              :src="photo.image + lowSrcQuery"
                              :alt="getTaskImageName(photo)"
                            />
                            <div class="img-overlay">
                              <span class="img-count">{{ index + 1 }}</span>
                              <span class="vertical-center-transform">{{
                                    getTaskImageName(photo)
                              }}</span>
                            </div>
                          </div>
                          <span class="attr-time"
                            ><img
                              src="/img/Alerts/timeGreen_icon.png"
                              class="mr3"
                            />
                            {{ photo.created_at | formattedPhpTime }}</span
                          >
                        </div>
                      </div>
                    </viewer>

                    <div
                      class="col-xs-6 col-md-4 photo"
                      v-for="n in dummyPicklImages"
                      :key="'dummy-image-' + n"
                    >
                      <div class="img-contain">
                        <div class="img-overlay">
                          <span class="img-count">{{
                            6 - dummyPicklImages + n
                          }}</span>
                          <span class="vertical-center-transform"
                            >No Image</span
                          >
                        </div>
                      </div>
                      <span class="attr-time"
                        ><img
                          src="/img/Alerts/timeGreen_icon.png"
                          class="mr3"
                        />
                        Taken at</span
                      >
                    </div>
                    <div
                      class="overlay"
                      v-if="!isSadmin && user_pickl_photos.length === 0"
                    >
                      <div class="vertical-center-transform normal-text">
                        Task images not available
                      </div>
                    </div>
                    <div class="col-sm-12" v-if="pickl.user_pickl_selfie">
                      <h2>Selfie Image</h2>
                      <img
                          class="img-responsive"
                          style="width:80%;margin:auto;border:solid 1px gainsboro;"
                          :src="
                            pickl.user_pickl_selfie.image + '?w=250&q=100'
                          "
                          @error="
                            pickl.store_location.store.image =
                              '/img/default_store_image.png'
                          "
                        />
                        <br/>
                    </div>
                  </div>
                </div>
                <div class="green-border-box" v-if="pickl.status =='COMPLETED' && !brand ">
                  <h3 class="text-center">Add Data Points</h3>
                  <div class="col-sm-6" v-for="taskDataPoint in taskDataPoints" :key="taskDataPoint.id">
                    <label v-html="taskDataPoint.dataPoint"></label><br/>
                    <input
                        type="text"
                        name="value"
                        v-model="taskDataPoint.value"
                        placeholder="Enter Value"
                        v-validate="'required'"
                        :class="{'input': true, 'is-danger': errors.has('add-staff.value') }"
                        >
                  </div>
                </div>
                <div class="green-border-box" v-if="pickl.status == 'APPROVED' || pickl.status == 'REJECTED'">
                  <h3 class="text-center"> Data Points</h3>
                  <div class="col-sm-6" v-for="taskDataPoint in pickl.task_data_point_answers" :key="taskDataPoint.id" style="margin-bottom:15px;">
                    <label v-html="taskDataPoint.task_data_point.data_point"></label><br/>
                      <span>{{taskDataPoint.text_answer}}</span>
                  </div>
                </div>
                <div class="green-border-box" v-if="$store.state.userData.role!='staff'">
                  <div
                    v-if="pickl.user_pickl"
                    class="row"
                    style="background:#ecf1eb;padding:1%;"
                  >
                    <div class="col-sm-12 pull-right">
                      <input
                        type="checkbox"
                        class="inline-checkbox"
                        v-model="openForDiscussion"
                        @change="toggleDiscussionStatus"
                        id="open-for-discussion"
                      />
                      <label for="open-for-discussion" style="display:inline;"
                        >Task Open For Discussion</label
                      >
                    </div>
                  </div>
                  <br />
                  <div
                    class="comments-section col-sm-12"
                    ref="comment-box"
                    v-if="userPicklComments.length > 0"
                    style="max-height:45vh;overflow-y:auto;"
                  >
                    <div
                      class="row"
                      v-for="comment in userPicklComments"
                      :key="comment.id"
                    >
                      <div
                        :class="
                          $store.state.userData.id == comment.userId
                            ? 'your-comment'
                            : 'team-comment'
                        "
                      >
                        <label
                          ><div
                            v-if="$store.state.userData.id != comment.userId"
                            style="float:left;"
                          >
                            {{ comment.user.name }}
                          </div>
                          <small style="float:right;">{{
                            comment.created_at | humanReadableDate
                          }}</small></label
                        >
                        <span v-html="comment.comment"></span>
                      </div>
                    </div>
                  </div>
                  <h2 v-else style="text-align:center;">
                    No Comments yet <br /><small
                      >(be the first one to comment here!)</small
                    >
                  </h2>
                  <div class="row comment-section" v-if="pickl.user_pickl && $store.state.userData.role!='staff'">
                    <hr />
                    <div class="col-sm-12">
                      <input
                        placeholder="Type your comment here..."
                        v-model="commentText"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-sm-12">
                      <button
                        class="btn btn-positive pull-right"
                        @click="saveComment"
                      >
                        Comment
                      </button>
                    </div>
                  </div>
                </div>
                <div class="green-border-box" v-if="pickl.status == 'APPROVED'" style="padding:3%;">
                  <span>Credits Given: <strong>${{ pickl.user_pickl.credits_assigned }}</strong></span>
                  <span style="text-align:right;float:right;">Reimbursement Given: <strong>${{ pickl.user_pickl.reimbursement_credits }}</strong></span>

                </div>
              </div>
              <div
                v-if="!brand && pickl.status === 'COMPLETED'"
                class="col-xs-12"
              >

              <div class="col-xs-12">
                <input type="checkbox" v-model="addReimbursementCredits" id="reimbursement-check" v-if="pickl.reimbursement_credits > 0"/> <label v-if="pickl.reimbursement_credits > 0" for="reimbursement-check" style="display:inline;">Add ${{ pickl.reimbursement_credits }} Reimbursement Credits</label><br/><br/>
                <input type="text" v-model="bonusCredits" placeholder="Enter Bonus Credits" style="width:50%;display:inline;margin-bottom: 0px;"/>

              </div>

                <div class="col-xs-4 text-right">

                  <button class="btn-lg-grey" @click="rejectPickl">
                    REJECT
                  </button>
                </div>
                <div class="col-xs-4 text-left">
                  <button class="btn-lg-green" @click="approvePickl" :disabled="changingStatus">
                    APPROVE
                  </button>
                </div>
              </div>

              <div
                class="col-xs-12"
                v-if="!brand && pickl.status === 'PENDING'"
              >
                <button
                  v-if="$store.state.userData.role=='super-admin'"
                  type="button"
                  class="btn-lg-grey"
                  data-toggle="modal"
                  data-target="#deletepicklpopup"
                  @click="getPicklDetails"
                >
                  Delete PICKL
                </button>
              </div>
            </div>
          </div>
          <div id="survey-details-section" class="tab-pane fade">
            <br />
            <div
              class="row"
              v-if="$store.state.settings.enable_survey_feature == 1"
            >
              <div class="single-quesion-box" v-if="pickl.survey != null">
                <h2>Survey Details Details</h2>
                <p>
                  <strong>Survey Name</strong>:
                  <span>{{ pickl.survey.name }}</span>
                </p>
                <p>
                  <strong>Description</strong>:
                  <span>{{ pickl.survey.description }}</span>
                </p>
              </div>
              <h3 v-else style="text-align:center;">
                Survey Not Available For This Alert!
              </h3>

              <div v-if="pickl.survey != null">
                <h2 style="margin-left: 1%">Questions</h2>
                <div
                  class="row single-quesion-box"
                  v-for="question in pickl.survey.survey_questions"
                  :key="question.id"
                >
                  <div class="col-md-6">
                    <strong>Question: </strong
                    ><span>{{ question.question }}</span>
                  </div>
                  <div class="col-md-6">
                    <strong>Question Type: </strong
                    ><span> {{ question.question_type }}</span>
                  </div>
                  <br />
                  <h4 style="margin-left: 2%; margin-top: 2%">Answers</h4>
                  <ul>
                    <li
                      v-for="answer in question.survey_question_answers"
                      :key="answer.id"
                    >
                      {{ answer.answer_option }}
                      <span
                        style="color:green;"
                        v-if="inPicklSurveyAnswers(answer.id)"
                        >✓</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h3 v-else style="text-align:center;">
              Survey Feature Coming Soon!
            </h3>
          </div>
        </div>
      </div>
    </div>
    <ConfirmPicklStatus :popupId="'approved-pickl-popup'" ref="approvedPicklPopup" @confirmed="confirmed" :type="confirmationType"/>
    <TaskSyncLogPopup v-if="pickl.user_pickl" :popupId="'task-sync-log-popup'" ref="taskSyncLogPopup" :userPickl="pickl.user_pickl"/>
  </div>
  <!-- </div> -->
</template>

<script>
import { plainText } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import ConfirmPicklStatus from '../SAdmin/ConfirmPicklStatus.vue'
import TaskSyncLogPopup from '@/components/SAdmin/TaskSyncLogPopup.vue'
export default {
  name: 'PicklDetails',
  components: {
    ConfirmPicklStatus,
    TaskSyncLogPopup
  },

  data: function () {
    return {
      changingStatus: false,
      confirmationType: 'approve',
      addReimbursementCredits: false,
      bonusCredits: '',
      taskDataPoints: [],
      openForDiscussion: 0,
      userPicklComments: [],
      commentText: '',
      hoverRating: 0,
      rating: 0,
      adminNote: '',
      needReview: '',
      ShowNotesToBrand: 1,
      lowSrcQuery: '?w=200&q=80&h=200&fit=crop',
      viewerOptions: {
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 0,
          reset: 0,
          prev: {
            size: 'large'
          },
          play: 0,
          next: {
            size: 'large'
          },
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 0,
          flipVertical: 0
        },
        filter: function (image) {
          return !image.src.endsWith('/img/Alerts/timeGreen_icon.png')
        },
        title: [
          1,
          function (image, imageData) {
            return image.alt
          }
        ],
        url: image => {
          return image.src.replace(this.lowSrcQuery, '')
        }
      },
      sharedLinkGenerated: false,
      sharedLink: ''
    }
  },
  props: {
    popupId: String,
    pickl: Object,
    brand: false
  },
  created () {
    this.$store.dispatch('getAppSettingsKeyValuePair')
  },
  methods: {
    getTaskImageName (photo) {
      if (photo.task_id === -3) {
        return 'Receipt Image'
      } else if (photo.task) {
        return photo.task.name
      } else {
        return 'Extra Image'
      }
    },
    generateShareLink () {
      let payload = {}
      this.$http.post('brand_shared_pickls/' + this.pickl.brand_id + '/' + this.pickl.id, payload).then((response) => {
        if (response.body.status === true) {
          this.sharedLinkGenerated = true
          this.sharedLink = process.env.VUE_APP_URL + '/overview/' + response.body.data.code
        }
      })
    },

    saveComment () {
      if (this.commentText.length > 0) {
        if (this.pickl.user_pickl) {
          let payload = {
            comment: this.commentText,
            userPicklId: this.pickl.user_pickl.id,
            userId: this.$store.state.userData.id
          }
          this.$store
            .dispatch('saveUserPicklComment', payload)
            .then(response => {
              this.userPicklComments.push(response.body)
              this.commentText = ''
            })
        }
      }
    },

    inPicklSurveyAnswers (answerId) {
      let status = false
      this.pickl.pickl_survey_answers.forEach(x => {
        if (x.answer_id === answerId) {
          status = true
          return true
        }
      })
      return status
    },

    redirectToPicklsPage () {},
    getLogName: function (type) {
      if (this.$store.state.userData && this.isSadmin && type === 'completed') { return 'Awaiting Approval' } else return type
    },
    getImagePath: function (i) {
      if (this.hoverRating > 0) {
        if (i <= this.hoverRating) return '/img/Alerts/starActive_icon.png'
        else return '/img/Alerts/star_icon.png'
      } else if (i <= this.rating) return '/img/Alerts/starActive_icon.png'
      else return '/img/Alerts/star_icon.png'
    },
    reverseRejectPickl () {
      let data = {
        admin_rating: this.rating,
        admin_note: this.adminNote,
        need_review: this.needReview ? 'Yes' : 'No'
      }
      this.$http
        .patch(
          'user_pickls/' + this.pickl.user_pickl.id + '/REVERSE_REJECTED',
          data
        )
        .then(
          response => {
            this.$notify({
              type: 'success',
              text: 'Pickl rejection has be reversed successfully!'
            })
            this.$emit('reversedRejection')
          },
          response => {
            this.$notify({ type: 'error', text: 'Failed to save pickl status' })
          }
        )
    },
    rejectPickl () {
      if (this.adminNote === '') {
        this.$notify({
          text: 'Please enter reason of rejection.',
          type: 'error'
        })
        return false
      } else if (this.adminNote.length > 120) {
        this.$notify({
          text: 'Notes should not be more than 120 characters.',
          type: 'error'
        })
        return false
      } else {
        // this.needReview = (this.needReview)? 'Yes': 'No'
        let data = {
          admin_rating: this.rating,
          admin_note: this.adminNote,
          need_review: this.needReview ? 'Yes' : 'No',
          show_notes_to_brand: this.ShowNotesToBrand,
          addedTaskDataPoints: this.getAddedTaskDataPoints

        }
        this.$http
          .patch('user_pickls/' + this.pickl.user_pickl.id + '/REJECTED', data)
          .then(
            response => {
              this.$notify({
                type: 'success',
                text: 'Pickl has been rejected successfully!'
              })
              this.$emit('statusUpdated')
            },
            response => {
              this.$notify({
                type: 'error',
                text: 'Failed to save pickl status'
              })
            }
          )
      }
    },
    approvePickl () {
      if (this.rating === 0) {
        this.$notify({
          text: 'Please select rating for this pickl.',
          type: 'error'
        })
        return false
      } else if (this.adminNote.length > 120) {
        this.$notify({
          text: 'Notes should not be more than 120 characters.',
          type: 'error'
        })
        return false
      } else {
        this.confirmationType = 'approve'
        let element = this.$refs.approvedPicklPopup.$el
        $(element).modal('show')
      }
    },
    confirmed (type) {
      console.log(type)
      if (type === 'approve') {
        this.confirmApprove()
      }
    },
    confirmApprove () {
      this.changingStatus = true
      let data = {
        admin_rating: this.rating,
        admin_note: this.adminNote,
        need_review: this.needReview ? 'Yes' : 'No',
        show_notes_to_brand: this.ShowNotesToBrand,
        addedTaskDataPoints: this.getAddedTaskDataPoints,
        addReimbursementCredits: this.addReimbursementCredits,
        bonusCredits: this.bonusCredits
      }
      this.$http
        .patch('user_pickls/' + this.pickl.user_pickl.id + '/APPROVED', data)
        .then(
          response => {
            this.$notify({
              type: 'success',
              text: 'Pickl has been approved successfully!'
            })
            this.$emit('statusUpdated')
            this.changingStatus = false
          },
          response => {
            this.$notify({
              type: 'error',
              text: 'Failed to save pickl status'
            })
            this.changingStatus = false
          }
        )
    },
    getPicklDetails () {
      this.$refs.closePicklDetailsPopupButton.click()
      this.$emit('getPicklDetails', this.pickl.id)
    },
    toggleDiscussionStatus () {
      this.$store
        .dispatch('updateUserPickl', {
          userId: this.pickl.user_pickl.user_id,
          id: this.pickl.user_pickl.id,
          openForDiscussion: this.openForDiscussion
        })
        .then(
          response => {
            this.$notify({
              type: 'success',
              text: 'Task Discussion status updated!'
            })
          },
          response => {}
        )
    },
    getTaskDataPoints (clear = true) {
      this.busy = true
      this.$store.dispatch('getTaskDataPoints', { query: this.query, limit: this.limit, offset: this.offset }).then((response) => {
        this.taskDataPoints.push(...response.response.body.taskDataPoints)
      }, (response) => {
        this.busy = false
      })
    }
  },
  computed: {

    getAddedTaskDataPoints () {
      let addedTaskDataPoints = []
      this.taskDataPoints.forEach(dataPoint => {
        if (typeof dataPoint.value !== 'undefined') {
          addedTaskDataPoints.push({
            task_data_point_id: dataPoint.id,
            text_answer: dataPoint.value
          })
        }
      })
      return addedTaskDataPoints
    },

    isSadmin () {
      return (
        this.$store.state.userData &&
        (this.$store.state.userData.role === 'super-admin' ||
          this.$store.state.userData.role === 'staff')
      )
    },
    dummyPicklImages () {
      if (this.user_pickl_photos.length > 0) {
        let count = 4 - this.user_pickl_photos.length
        if (count < 0) {
          return 0
        } else {
          return count
        }
      } else {
        return 6
      }
    },
    dummyProductImages () {
      if (this.pickl.product.images && this.pickl.product.images.length > 0) {
        let count = 4 - this.pickl.product.images.length
        if (count < 0) {
          return 0
        } else {
          return count
        }
      } else {
        return 6
      }
    },
    user_pickl_photos () {
      if (
        this.pickl &&
        this.pickl.user_pickl &&
        this.pickl.user_pickl.user_pickl_photos &&
        this.$store.state.userData &&
        (this.isSadmin || this.pickl.status === 'APPROVED')
      ) {
        return this.pickl.user_pickl.user_pickl_photos
      } else {
        return []
      }
    },
    sorted_user_pickl_photos () {
      let sortedPhotosList = []
      if (
        this.pickl &&
        this.pickl.user_pickl &&
        this.pickl.user_pickl.user_pickl_photos &&
        this.$store.state.userData &&
        (this.isSadmin || this.pickl.status === 'APPROVED')
      ) {
        this.pickl.user_pickl.user_pickl_photos.forEach((photo) => {
          if (photo.task) {
            sortedPhotosList.push(photo)
          }
        })
        this.pickl.user_pickl.user_pickl_photos.forEach((photo) => {
          if (!photo.task) {
            sortedPhotosList.push(photo)
          }
        })
        console.log(sortedPhotosList)
        return sortedPhotosList
      } else {
        return []
      }
    },
    productImages () {
      let images = []
      if (
        this.pickl &&
        this.pickl.product &&
        this.pickl.product.images &&
        this.pickl.product.images.length > 0
      ) {
        this.pickl.product.images.forEach(x => {
          images.push({
            path: x.image,
            caption:
              x.description === null ? this.pickl.product.name : x.description
          })
        })
      }
      return images
    },
    taskImages () {
      let images = []
      this.user_pickl_photos.forEach(x => {
        images.push({
          path: x.image,
          caption: x.task ? x.task.name : 'Extra Image'
        })
      })
      return images
    }
  },
  watch: {
    pickl () {
      if (this.pickl.user_pickl) {
        this.$store
          .dispatch(
            'getUserPicklCommentsByUserPicklId',
            this.pickl.user_pickl.id
          )
          .then(response => {
            this.openForDiscussion = this.pickl.user_pickl.open_for_discussion
            this.userPicklComments = response.body.userPicklCommentPageable
          })
        this.getTaskDataPoints()
      }
      Object.assign(this.$data, this.$options.data.apply(this))
    }
  }
}
</script>

<style scoped>
.vertical-center-transform {
  width: 100%;
  text-align: center;
}

.green-border-box {
  margin-bottom: 15px;
}

.border-circle {
  width: 19%;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  margin-bottom: 44px;
}

.border-circle:before {
  content: ' ';
  padding-top: 100%;
  display: block;
}

hr.journey-string {
  width: 5%;
  margin: 10% 4px 0;
  vertical-align: top;
  border-width: 2px;
  border-color: #d9d9d9;
  display: inline-block;
}

.modal-content .green-border-box .plain-center-header {
  font-weight: 500;
  font-size: 22px;
  color: #333333;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.circle-label {
  font-size: 16px;
  color: black;
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  bottom: -50px;
  left: 0;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
}

.circle-label:last-child {
  padding-right: 0;
}

.completion-time {
  border-radius: 360px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  background: #f02a38;
  background: -webkit-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: -moz-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: linear-gradient(to right, #f02a38 0%, #ff434c 100%);
}

.rating img:last-child {
  margin-right: 0;
}

.rating strong {
  font-weight: 500;
  font-size: 18px;
  color: black;
  margin-right: 5px;
}

.top-part {
  padding: 15px;
}

.middle-part {
  padding: 0 15px 15px;
}

.top-part h4,
.top-part h5 {
  padding: 0 75px;
  margin: 0;
}

.top-part h4 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 30px;
}

.top-part h5 {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-bottom: 8px;
}

.product-logo {
  left: auto;
  right: 0;
}

.middle-part .address {
  font-size: 17px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

/* .middle-part .address:first-of-type {
  min-height: 48px;
} */

.middle-part .status {
  font-size: 19px;
  font-weight: 500;
}

.bottom-part {
  padding: 10px 0;
  background: #f7f8f9;
  font-weight: 500;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.8);
  vertical-align: text-top;
  line-height: 35px;
}

.bottom-part img {
  vertical-align: text-top;
  margin-right: 3px;
}

.bottom-part .pickl-date {
  border-right: 1px solid #cfd1d3;
}

.rating {
  background-color: #f7f8f9;
  /* padding: 30px 20px; */
  float: left;
  width: 100%;
  min-height: 233px;
}

.issues {
  min-height: 233px;
}

.rating img {
  width: 19px;
  height: 17px;
  margin-right: 3px;
}

.rating img:last-child {
  margin-right: 0;
}

.attr-imgs {
  padding: 20px 10px 0;
}

.photo {
  padding: 0 10px;
  margin-bottom: 40px;
}

.img-contain {
  position: relative;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.img-contain:before {
  padding-top: 100%;
  display: block;
  content: ' ';
}

.img-contain img {
  width: 100%;
  height: auto;
  cursor: pointer;
  /* max-width: 100%;
  max-height: 100%; */
}

.img-contain .img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.3); */
  font-weight: 500;
  font-size: 22px;
  text-transform: capitalize;
  pointer-events: none;
}

.img-contain .img-overlay .img-count {
  border-radius: 50%;
  background-color: #00e05e;
  border: 2px solid white;
  font-size: 17px;
  width: 25px;
  height: 25px;
  display: block;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 21px;
}

.attr-time {
  font-size: 17px;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.normal-text {
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
}

label {
  width: 100%;
  color: rgba(89, 89, 89, 0.9);
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
}

.rating label {
  margin-top: 30px;
}

.btn-lg-grey,
.btn-lg-green {
  width: 200px;
}

textarea {
  margin-bottom: 0;
}

.rating h5 {
  font-weight: 500;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
}

.rate-picklr {
  cursor: pointer;
  margin-bottom: 45px;
}

.rate-picklr span {
  display: inline-block;
}

.rate-picklr img {
  width: 33px;
  height: 31px;
  margin: 0 8px;
}

.showOnMobile {
  display: none;
}

.address img {
  vertical-align: text-top;
}

.img-contain .img-overlay span:last-child {
  padding: 3px;
  background: rgba(0, 0, 0, 0.8);
}

.overlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.overlay .vertical-center-transform {
  color: white;
}

.first-block {
  min-height: 230px;
}

.product-details .photo {
  margin-bottom: 14px;
}

.product-details .attr-imgs {
  padding-top: 0;
}

.product-details .missing-image {
  border: 1px dashed black;
}

@media only screen and (min-width: 1096px) and (max-width: 1199px) {
  .bottom-part {
    font-size: 15px;
    line-height: 30px;
  }

  .bottom-part > div {
    padding-left: 0;
    padding-right: 0;
  }

  .bottom-part img {
    height: 17px;
    width: auto;
  }
}
.modal-dialog.modal-lg {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .border-circle {
    font-size: 13px;
    line-height: 17px;
  }

  .border-circle .circle-label {
    font-size: 13px;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .bottom-part {
    padding: 0 15px;
  }

  .bottom-part .pickl-date,
  .bottom-part .pickl-time {
    padding: 10px 0;
  }

  .bottom-part .pickl-date {
    border-bottom: 1px solid #cfd1d3;
    border-right: none;
  }

  .rating {
    min-height: auto;
  }

  .issues {
    min-height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .company-logo {
    left: 20%;
  }

  .product-logo {
    right: 20%;
  }

  .top-part h4 {
    padding: 0;
    padding-top: 70px;
  }
  .top-part h5 {
    padding: 0;
  }

  .showOnMobile {
    display: inline;
  }

  /* .rating {
    padding: 15px;
  } */

  .rating strong {
    font-size: 16px;
  }

  .rated img {
    width: 15px;
    height: auto;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }

  .border-circle {
    font-size: 14px;
    line-height: 17px;
    min-width: 75px;
    min-height: 75px;
    display: block;
    margin-bottom: 0;
  }

  .border-circle .circle-label {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 20px);
    width: auto;
    text-align: left;
    bottom: auto;
    height: auto;
  }

  hr.journey-string {
    width: 100%;
    border-width: 1px;
    margin: 25px 0;
  }

  .btn-lg-grey,
  .btn-lg-green {
    width: 120px;
  }
}

.review-attention strong {
  color: #cb4f40;
}
.team-comment {
  float: left;
  text-align: left;
  border: 1px solid #12db6d;
  margin: 10px;
  border-radius: 10px;
  padding: 5px;
  background: ghostwhite;
}
.your-comment {
  float: right;
  text-align: right;
  border: 1px solid #12db6d;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  background: #e8fff2;
}
</style>
