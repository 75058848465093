









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class ConfirmPicklStatus extends Vue {
  @Prop() popupId!: string
  @Prop() type!: string

  confirmed () {
    this.$emit('confirmed', this.type)
    let closeButton: HTMLElement = this.$refs.confirmPicklStatusCloseButton as HTMLElement
    closeButton.click()
  }
}
