

































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class TaskSyncLogPopup extends Vue {
  @Prop() popupId!: string;
  @Prop() userPickl!: object;
  @Prop() userId!: number;

  mounted () {
    console.log(this.userPickl)
  }
}
