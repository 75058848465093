import { render, staticRenderFns } from "./TaskSyncLogPopup.vue?vue&type=template&id=035a915b&"
import script from "./TaskSyncLogPopup.vue?vue&type=script&lang=ts&"
export * from "./TaskSyncLogPopup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/naveenkumar/Sites/pickl-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('035a915b')) {
      api.createRecord('035a915b', component.options)
    } else {
      api.reload('035a915b', component.options)
    }
    module.hot.accept("./TaskSyncLogPopup.vue?vue&type=template&id=035a915b&", function () {
      api.rerender('035a915b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SAdmin/TaskSyncLogPopup.vue"
export default component.exports